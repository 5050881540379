.swiper1 {
  width: 100%;
  height: 20rem;
}
@media screen and (max-width:1000px) {

    .swiper1 {
  width: 100%;
  height: 70%;
}
}




.swiper2 {
  width: 100%;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
}



.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper1 {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper1 .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper1 .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper1 .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper2 .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
