.swipers {
    width: 350px;
    height: 500px;
  }
   @media screen and ((max-width:990px) and (min-width:768px)) {
    .swipers {
        width: 270px;
        height: 400px;
      }
  }
  @media screen and ((max-width:448px) ) {
    .swipers {
        width: 260px;
        height: 350px;
      }
  } 
  .swipers .swiper-slide {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgb(248, 240, 240);
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }
  .swipers .swiper-slide iframe{
    z-index: 1;
    cursor: none;
  }
  
  