.container {
 
  padding: 4rem 1rem;
  margin: 0 auto;
}

.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiper_container {
  height: fit-content;
  padding: 2rem 0;
  position: relative;
}

.swiperslide {
  width: fit-content;
  height: fit-content;
  position: relative;
}

@media (max-width: 500px) {
  .swiper_container {
    height: fit-content;
  }
  .swiperslide {
    width: fit-content !important;
    height: fit-content !important;
  }
  .swiperslide img {
    width: 15rem !important;
    height: 20rem !important;
  }
}

.swiperslide img {
  width: 20rem;
  height: 27rem;
 
  object-fit: cover;
}
.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
  display: none;
}

.slider-controler .swiper-button-prev {
  left: 58% !important;
  transform: translateX(-58%) !important;
  display: none;
}
.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
  display: none;
}
