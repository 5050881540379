@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/intro');
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Butterfly+Kids&family=Cinzel:wght@400..900&family=Cookie&family=Crimson+Pro:ital,wght@0,200..900;1,200..900&family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Dancing+Script:wght@400..700&family=Domine:wght@400..700&family=Great+Vibes&family=Imperial+Script&family=Khand:wght@300;400;500;600;700&family=Marcellus&family=Overpass:ital,wght@0,100..900;1,100..900&family=Pacifico&family=Playball&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Rakkas&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sevillana&family=Tinos:ital,wght@0,400;0,700;1,400;1,700&family=Truculenta:opsz,wght@12..72,100..900&family=WindSong:wght@400;500&display=swap');
@import url('https://fonts.cdnfonts.com/css/dream');
@import url('https://db.onlinewebfonts.com/c/a309629cc9638b4cde71cd37315e56df?family=Weiss-Fraktur');
@import url('https://db.onlinewebfonts.com/c/401c2fc5d85b55e63da3e9a5e247095e?family=KatieRose+W00+Regular');
@import url('https://db.onlinewebfonts.com/c/641c58c8d2163694f1fab0d855f86f7f?family=LTC+Artscript+W01+Alternates');
@import url('https://fonts.googleapis.com/css2?family=Antic+Didone&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antic+Didone&family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antic+Didone&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Butterfly+Kids&family=Cookie&family=Crimson+Pro:ital,wght@0,200..900;1,200..900&family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Dancing+Script:wght@400..700&family=Domine:wght@400..700&family=Great+Vibes&family=Imperial+Script&family=Khand:wght@300;400;500;600;700&family=Marcellus&family=Overpass:ital,wght@0,100..900;1,100..900&family=Pacifico&family=Playball&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Rakkas&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sevillana&family=Tinos:ital,wght@0,400;0,700;1,400;1,700&family=Truculenta:opsz,wght@12..72,100..900&family=WindSong:wght@400;500&display=swap');
@import url('https://db.onlinewebfonts.com/c/24c77049c751ff638c51cc41f1a3258d?family=Taken+by+Vultures+Demo');
.swiper-button-prev:after, .swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 10px;
  text-transform: none !important;
  letter-spacing: 0;
  color: white;
}
.swiper-button-prev, .swiper-button-next::after {
  font-family: swiper-icons;
  font-size: 10px;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}
.swiper-button-prev, .swiper-button-next {
  position: absolute;
  border:none;
  font-size: 10px;
  padding: 0rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
  background-color: transparent;
  color: white;
 width: 0.1rem;
 height: 0.1rem;
padding-left: 1.5rem;
padding-right: 1.5rem;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: 1.2rem;
  left: auto;
}
body{
  overflow-x: hidden;
}

.nav{
  font-family: 'Dream', sans-serif;
}
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0.75;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev {
  left: 0;
  right: auto;
}

.waves {
  position: absolute;
  width: 150px;
  height: 150px;
 
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 100%;
  right: -50px;
  bottom: -60px;
  z-index: -1;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}
@keyframes waves{
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
}
100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
}
.box{
  position: relative;

  transform-style: preserve-3d;
  animation: animate 60s linear infinite;

  
} @keyframes animate{
  0%{
    transform: perspective(1200px) rotateY(0deg);
  }
  100%{
    transform: perspective(1200px) rotateY(360deg);
  }
}
.box span{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform-style: preserve-3d;
 
  -webkit-box-reflect: below 0px linear-gradient(transparent,transparent,#f7efef73);
}
.box span img{
  position: absolute;
  left:0;
  top:0;
  width: 100%;
height: 100%;
padding-left:0rem;
object-fit: cover;
}



.box1{
  position: relative;

  transform-style: preserve-3d;
  animation: animates 60s linear infinite;
 
  
} 
@keyframes animates{
  0%{
    transform: perspective(2000px) rotateY(0deg);
  }
  100%{
    transform: perspective(2000px) rotateY(360deg);
  }
}
.box1 span{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform-style: preserve-3d;

  -webkit-box-reflect: below 0px linear-gradient(transparent,transparent,#f7efef73);
}
.box1 span img{
  position: absolute;
  left:0;
  top:0;
  width: 100%;
height: 100%;
padding-left:0rem;
object-fit: cover;
}

#link_button-1325-20 {
  border-radius: 10px;
  color: color(5);
  /* margin-top: 40px; */
}
.primary-btn:after {
  position: absolute;
  top: 13px;
  left: 13px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border-top-style: none;
  border-top-color: #927025;
  border-right-color: #927025;
  border-bottom-color: #927025;
  border-left-color: #927025;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: none;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  transition-duration: .4s;
  transition-timing-function: ease;
  content: "";
}